import React from 'react';

const MedicalCard = () => (
    <a
      id="projects-card-medical"
      className="projects-card"
      href="https://www.themedical.co.uk/"
      rel="nofollow noopener noreferrer"
      target="_blank"
      title="The Medical"
    >

      <div className="projects-card__content flow--xsmall">
        <span className="type-heading">The Medical</span>
        <div class="cluster projects-card_tags">
          <div>
              <span className="cluster__item tag">Design</span>
              <span className="cluster__item tag">Craft CMS</span>
          </div>
        </div>
      </div>
      <footer class="projects-card__footer">
          <svg
            viewBox="0 0 74 8"
            width="54"
            height="6"
            className="projects-card__arrow"
          >
            <use xlinkHref="#icon-arrow-right" />
          </svg>
        </footer>
    </a>
)

export default MedicalCard;