import React from 'react'
import { Link } from 'gatsby'

const AreYouAnAgency = () => (
  <section className="wrapper-chunky bg-white">
    <div className="container-medium">
      <div className="grid-x grid-margin-x small-only-rhythm">
        <div className="cell small-12 medium-7 cta_message">
          <h2 className="type-subtitle breathe--small _has-subhead">
            <span>Are you an agency?</span>
            <svg
              viewBox="0 0 74 8"
              width="74"
              height="8"
              className="icon-arrow-base _has-sibling"
            >
              <use xlinkHref="#icon-arrow-right" />
            </svg>
          </h2>
          <span className="type-label type-highlight">
            We do white label projects too!
		  </span>
		  <p className="type-body">We have experience working as a white label development partner and can provide expert development assistance and consultation on request.</p>
        </div>
        <div className="cell small-12 medium-5 cta_actions">
          <Link to="/contact" className="btn-alpha" title="Get in touch">
            Get in touch
          </Link>
        </div>
      </div>
    </div>
  </section>
)

export default AreYouAnAgency
