import React from 'react';
import { withPrefix } from 'gatsby';

const PergolasCard = () => (
  <a
  id="projects-card-pergolas"
  className="projects-card projects-card--pergolas"
  href="https://www.perfectpergolas.co.uk/"
  rel="nofollow noopener noreferrer"
  target="_blank"
  title="Perfect Pergolas"
>

  <div className="projects-card__content">
    <div class="flow--xsmall">
      <h3 className="type-heading">Perfect Pergolas</h3>
      <div class="cluster projects-card_tags">
          <div>
              <span className="cluster__item tag">Design</span>
              <span className="cluster__item tag">Gatsby</span>
          </div>
        </div>
    </div>
  </div>
  <footer class="projects-card__footer">
  <svg
      viewBox="0 0 74 8"
      width="54"
      height="6"
      className="projects-card__arrow"
    >
      <use xlinkHref="#icon-arrow-right" />
    </svg>
    <img src="/images/clients/perfect-pergolas/asset.svg" className="projects-card__asset" />
  </footer>
</a>
)

export default PergolasCard;