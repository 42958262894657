import React from 'react';

const CirenHousingCard = () => (
  <a
  id="projects-card-ciren"
  className="projects-card projects-card--ciren"
  href="https://www.cirencesterhousing.org.uk/"
  rel="nofollow noopener noreferrer"
  target="_blank"
  title="Cirencester Housing"
>

  <div className="projects-card__content">

    <div className="ciren-housing__titleplate flow--xsmall">
      <span className="type-heading">Cirencester Housing</span>
      <span className=" type-metadata breathe--small"><span>Design / Wordpress</span>
        <svg
          viewBox="0 0 74 8"
          width="54"
          height="6"
          className="projects-card__arrow"
        >
          <use xlinkHref="#icon-arrow-right" />
        </svg>
      </span>
    </div>

  </div>

  <footer class="projects-card__footer">
    <img
      src="/images/clients/cirencester-housing/asset.svg"
      alt="An illustrated line of white houses with birds flying overhead"
      className="projects-card__asset"
    />
  </footer>
</a>
)

export default CirenHousingCard;