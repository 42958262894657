import React from 'react';
import { withPrefix } from 'gatsby';

const PeelCard = () => (
<a
            id="projects-card-peel"
            className="projects-card projects-card--peel"
            href="https://zone.projectpeel.org/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="PEEl Project"
          >

            <div className="projects-card__content">
              <span className="type-heading">PEEL Project</span>
              <hr className="sep-short-peel" />
              <div className="projects-card_tags">
                <span className="type-metadata">Design / React / Laravel</span>
              </div>
            </div>
            <footer class="projects-card__footer">
            <svg
                viewBox="0 0 74 8"
                width="54"
                height="6"
                className="projects-card__arrow"
              >
                <use xlinkHref="#icon-arrow-right" />
              </svg>
            </footer>
          </a>
)

export default PeelCard;