import React from 'react';
import { withPrefix } from 'gatsby';

const TipCard = () => (
    <a
    href="https://www.theindependentpharmacy.co.uk/"
    target="_blank"
    rel="nofollow noopener noreferrer"
    id="projects-card-tip"
    className="projects-card projects-card--large projects-card--tip"
    title="The Independent Pharmacy"
    >

    <div className="projects-card__content flow--small">
        <svg
        viewBox="0 0 100 31"
        className="projects-card__logo"
        >
        <use xlinkHref="#icon-client-tip" />
        </svg>
        <div class="cluster">
            <div>
                <span className="cluster__item tag">UX</span>
                <span className="cluster__item tag">Design</span>
                <span className="cluster__item tag">Development</span>
                <span className="cluster__item tag">Laravel</span>
                <span className="cluster__item tag">Gatsby</span>
                <span className="cluster__item tag">Craft CMS</span>
            </div>
        </div>
    </div>
    <footer className="projects-card__footer">
        <img
            src={withPrefix('images/clients/tip/asset.svg')}
            alt="A white iPhone X displaying the Independent Pharmacy homepage"
            className="projects-card__asset"
        />
            <svg
                viewBox="0 0 74 8"
                width="74"
                height="8"
                className="projects-card__arrow"
            >
                <use xlinkHref="#icon-arrow-right" />
            </svg>
        </footer>
    </a>
)

export default TipCard;